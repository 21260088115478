<template lang="html">

  <section class="your-data-safe">
    <div class="container text-legals">
      <h1 class="title">{{$t('menus.footer.yourDataSafe')}}</h1>
      <article v-html="$t('legalTexts.yourDataSafe')"></article>
    </div>
  </section>

</template>

<script lang="js">

  export default {
    name: 'your-data-safe',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .your-data-safe {}
</style>